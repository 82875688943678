<template>
  <div id="addsurgery">
    <!-- back -->
    <div class="vx-row w-full">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
    </div>
    <p class="warning">*{{ $t("AddNewSurgeryNote") }}</p>
    <div style="width: 100%; margin: auto">
      <div class="vx-row">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("Active") }}*</div>
        <vs-checkbox class="w-full vx-col lg:w-1/3" v-model="treatment.Active"></vs-checkbox>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("NameEn") }}*</div>
        <vs-input
          v-model="treatment.Surgery.NameEN"
          class="w-full vx-col lg:w-1/3"
          :disabled="treatment.ID > 0"
        />
      </div>

      <div class="vx-row mt-2">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("NameAr") }}</div>
        <vs-input
          v-model="treatment.Surgery.NameAR"
          class="w-full vx-col lg:w-1/3"
          :disabled="treatment.ID > 0"
        />
      </div>

      <div class="vx-row mt-2">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("SurgeryCategory") }}*</div>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full vx-col lg:w-1/3"
          label="Name"
          v-model="treatment.Surgery.SurgeryCategoryID"
          :options="surgeryCategories"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('SurgeryCategory')"
          :disabled="treatment.ID > 0"
        >
        </v-select>
      </div>
      <!-- Specialities -->
      <div class="vx-row mt-2">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("Specialities") }}*</div>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full vx-col lg:w-1/3"
          label="Name"
          v-model="treatment.Surgery.SpecialtyID"
          :options="specialities"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('Specialities')"
          @input="changeSpecialty"
          :disabled="treatment.ID > 0"
        >
        </v-select>
      </div>
      <!-- DefaultHospitalPrice -->
      <div class="vx-row mt-2">
        <div class="vx-col w-full lg:w-1/3 mt-5">{{ $t("HospitalPrice") }}*</div>
        <vs-input
          type="money"
          v-model="treatment.DefaultHospitalPrice"
          class="vx-col lg:w-1/3 sm:w-1/2"
        />
      </div>
      <!-- DefaultDoctorPrice -->
      <div class="vx-row mt-2">
        <div class="vx-col w-full lg:w-1/3 mt-5">
          {{ $t("DoctorPriceSurgery") }}
        </div>
        <vs-input
          type="money"
          v-model="treatment.DefaultDoctorPrice"
          class="vx-col lg:w-1/3 sm:w-1/2"
        />
      </div>
      <!-- Currency -->
      <div class="vx-row mt-2">
        <div class="vx-col w-full lg:w-1/3 mt-5">{{ $t("Currency") }}*</div>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full vx-col lg:w-1/3"
          label="Name"
          v-model="treatment.CurrencyID"
          :options="currencies"
          :placeholder="$t('Currency')"
          :reduce="(obj) => obj.ID"
        />
      </div>
      <!-- Discount -->
      <div class="vx-row mt-2">
        <div class="vx-col lg:w-1/3 mt-5">{{ $t("Discount") }}</div>
        <div class="vx-col lg:w-1/3 mt-5">
          <!-- :click="ChangeIsPercentage(false)" -->
          <vs-radio
            v-model="treatment.IsPercentage"
            :vs-value="false"
            class="mr-4"
            vs-name="IsPercentage"
            >{{ $t("Value") }}</vs-radio
          >
          <!-- :click="ChangeIsPercentage(true)" -->
          <vs-radio
            v-model="treatment.IsPercentage"
            :vs-value="true"
            class="mr-4"
            vs-name="IsPercentage"
            >{{ $t("Percentage") }}</vs-radio
          >

          <div v-if="treatment.IsPercentage != false &&treatment.IsPercentage !=null">
            <vs-input
              class="w-full"
              label="Percentage Discount"
              v-model="treatment.PercentageDiscount"
            />
          </div>
          <div v-if="treatment.IsPercentage != true &&treatment.IsPercentage !=null">
            <vs-input
              class="w-full"
              label="Discount"
              v-model="treatment.Discount"
            />
          </div>
        </div>
      </div>
 
      <vs-card class="mt-5">
        <div class="mt-5 vx-row w-full">
          <div class="vx-col lg:w-1/4 w-full">
            {{ $t("SurgeryTreatmentIncludes") }}
          </div>

          <div class="vx-col lg:w-2/3 w-full">
            <point
              class="ml-0 mr-0"
              :haveRemove="true"
              @remove="
                removeSurgeryIncludedItem(
                  treatment.HospitalSurgeryIncludedItems,
                  item,
                  index
                )
              "
              v-for="(item, index) in treatment.HospitalSurgeryIncludedItems"
              :data="item.SurgeryItem"
              :key="item.ID"
            />
            <addItem
              @event="
                ShowAddSurgeryItemPopup = true;
                activeList = treatment.HospitalSurgeryIncludedItems;
                addItemModel = { viewType: 1 };
              "
            />
          </div>
        </div>
      </vs-card>
      <!-- SurgeryTreatmentNotIncludes -->
      <vs-card class="mt-5">
        <div class="mt-5 vx-row w-full">
          <div class="vx-col lg:w-1/4">
            {{ $t("SurgeryTreatmentNotIncludes") }}
          </div>

          <div class="vx-col lg:w-2/3">
            <point
              class="ml-0 mr-0"
              :haveRemove="true"
              @remove="
                removeSurgeryExcludedItem(
                  treatment.HospitalSurgeryExcludedItems,
                  item,
                  index
                )
              "
              v-for="(item, index) in treatment.HospitalSurgeryExcludedItems"
              :data="item.SurgeryItem"
              :key="index"
            />
            <addItem
              @event="
                ShowAddSurgeryItemPopup = true;
                activeList = treatment.HospitalSurgeryExcludedItems;
                addItemModel = { viewType: 1 };
              "
            />
          </div>
        </div>
      </vs-card>
      <!-- RequiredDocs -->
      <vs-card class="mt-5">
        <div class="mt-5 vx-row w-full">
          <div class="vx-col lg:w-1/4">
            {{ $t("RequiredDocs") }}
          </div>

          <div class="vx-col lg:w-2/3">
            <point
              class="ml-0 mr-0"
              :haveRemove="true"
              @remove="
                removeDocumentItem(
                  treatment.HospitalSurgeryRequiredDocuments,
                  item,
                  index
                )
              "
              v-for="(item, index) in treatment.HospitalSurgeryRequiredDocuments"
              :data="item"
              :key="index"
            />
            <addItem
              @event="
                ShowAddSurgeryItemPopup = true;
                activeList = treatment.HospitalSurgeryRequiredDocuments;
                addItemModel = { viewType: 2 };
              "
            />
          </div>
        </div>
      </vs-card>

      <p>{{ $t("AssignDoctors") }}</p>

      <div class="vx-row mt-5" id="customRow">
        <vs-card
          id="custumcard"
          class="vx-col lg:w-1/3 ml-2 mr-2"
          style="height: 40vh; overflow-y: scroll"
        >
          <h3 class="ActiveCardHeader primaryBackColor">{{ $t("Doctors") }}</h3>
          <div class="m-3">
            <vs-input
              v-model="search.DoctorName"
              class="w-full"
              name="search"
              :placeholder="$t('Search')"
            />
            <div
              v-for="item in filterDoctor"
              :key="item.ID"
              class="shadow-md w-full mt-2"
            >
              <p class="m-5 p-3 vx-row">
                <vs-checkbox
                  v-model="item.IsSelected"
                  @input="DoctorChecked(item)"
                ></vs-checkbox>
                {{ item.Name }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="vx-col lg:w-1/3 ml-2 mr-2"
          style="height: 40vh; overflow-y: scroll"
        >
          <h3 class="primarycolor">{{ $t("Doctors") }}</h3>
          <point
            v-for="(item, index) in treatment.HospitalSurgeryDoctors"
            :data="item.Doctor"
            :key="index"
          />
        </vs-card>
      </div>
      <div class="w-full mb-10">
        <div style="text-align: end">
          <vs-button
            color="#004477"
            :disabled="!isFormValid"
            @click="SaveHospitalSurgery()"
            class="mt-3 customizer-btn text-sm text-white"
            ><h6 style="color: white">{{ $t("Save") }}</h6>
          </vs-button>
        </div>
      </div>
    </div>

    <vs-popup title="" :active.sync="ShowAddSurgeryItemPopup">
      <AddSurgeryItemPopup
        :itemModel="addItemModel"
        v-if="ShowAddSurgeryItemPopup"
        @closePop="ShowAddSurgeryItemPopup = false"
        @AddItem="AddNewItemToList()"
      ></AddSurgeryItemPopup>
    </vs-popup>

  </div>
</template>
<script>
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import addItem from "@/components/surgery/addItem.vue";
import point from "@/components/point.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import AddSurgeryItemPopup from "./AddSurgeryItemPopup.vue";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";

export default {
  components: {
    addItem,
    point,
    AddSurgeryItemPopup,
  },
  data() {
    return {
      EnglishLan: false,
      ShowAddSurgeryItemPopup: false,
      points: [],
      treatment: {
        Surgery: {},
        HospitalSurgeryDoctors: [],
        HospitalSurgeryRequiredDocuments: [],
        HospitalSurgeryExcludedItems: [],
        HospitalSurgeryIncludedItems: [],
      },
      addItemModel: {},
      doctorList: [],
      search: { DoctorName: "" },
      activeList: [],
    };
  },
  methods: {
    // ChangeIsPercentage(check) {
    //   debugger;
    //   this.treatment.IsPercentage = check;
    //   debugger;
    // },
    AddNewItemToList() {
      debugger
      if(this.activeList==null||this.activeList==undefined){
        this.activeList = []
      }
      debugger
      if (this.addItemModel.viewType == 1)
      {
      var vm=this;
        var item=this.SurgeryItems.filter(x=>x.ID == vm.addItemModel.SurgeryItemID&&x.ID!=0)[0];
        this.activeList.push({
         // SurgeryItemID : item.ID,
          SurgeryItem: {
          
            SurgeryItemLockUpId:  this.addItemModel.SurgeryItemID==0?null:this.addItemModel.SurgeryItemID,
            Name: item!=undefined?item.Name : this.addItemModel.Name,
            NameEN: item!=undefined?item.Name: this.addItemModel.Name,
            NameAR: item!=undefined?item.Name :this.addItemModel.Name,
          },
        });
      }
        
debugger
      if (this.addItemModel.viewType == 2) this.activeList.push(this.addItemModel);
debugger
      this.ShowAddSurgeryItemPopup = false;
    },
    DoctorChecked(doctor) {
      debugger;
      var index = this.treatment.HospitalSurgeryDoctors.findIndex(
        (obj) => obj.Doctor.ID == doctor.ID
      );
      if (index == -1) {
        this.treatment.HospitalSurgeryDoctors.push({
          DoctorID: doctor.ID,
          Doctor: doctor,
        });
      } else {
        this.treatment.HospitalSurgeryDoctors.splice(index, 1);
      }
    },
    setSelectedDoctor() {
      if (this.doctorList && this.treatment.HospitalSurgeryDoctors) {
        this.doctorList.forEach((ele) => {
          if (
            this.treatment.HospitalSurgeryDoctors.findIndex(
              (obj) => obj.Doctor.ID == ele.ID
            ) > -1
          )
            ele.IsSelected = true;
        });
      }
    },
    removeSurgeryIncludedItem(list, item, index) {
      debugger;
      list.splice(index, 1);
      if (item.ID) {
        debugger;
        this.$store.dispatch("HospitalList/DeleteSurgeryIncludeItem", item.ID);
      }
    },
    removeSurgeryExcludedItem(list, item, index) {
      debugger;
      list.splice(index, 1);
      if (item.ID) {
        debugger;
        this.$store.dispatch("HospitalList/DeleteSurgeryExcludeItem", item.ID);
      }
    },
    removeDocumentItem(list, item, index) {
      list.splice(index, 1);
      if (item.ID)
        this.$store.dispatch("HospitalList/DeleteSurgeryDocumentItem", item.ID);
    },
    SaveHospitalSurgery() {     
      this.$vs.loading();
      debugger;
      if (this.treatment.IsPercentage) {
        this.treatment.Discount = undefined;
      } else {
        this.treatment.PercentageDiscount = undefined;
      }
      debugger;
      if (this.treatment.ID > 0) {
        this.$store
          .dispatch("HospitalList/UpdateHospitalSurgery", this.treatment)
          .then((res) => {
            this.$vs.loading.close();

            this.back();
            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.back();
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        debugger;
        this.treatment.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.$store
          .dispatch("HospitalList/AddHospitalSurgery", this.treatment)
          .then((res) => {
            this.$vs.loading.close();

            this.back();
            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.back();
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }

     
    },

    back() {
      this.$router.go(-1);
    },
    searchHospitalSpecialty() {
      this.$store.dispatch("HospitalList/SearchHospitalSpecialtys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      });
    },
    changeSpecialty() {
      debugger;
      this.getHospitalDoctor();
    },
    getHospitalDoctor() {
      debugger;

      this.$store
        .dispatch("HospitalList/SearchHospitalDoctors", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
          SpecialtyID: this.treatment.Surgery.SpecialtyID,
        })
        .then((res) => {
          debugger;
          var doctorLists = [];
          var model = res.data.Data;
          model.forEach((ele) => {
            if (doctorLists.findIndex((r) => r.ID == ele.Doctor.ID) == -1) {
              var obj = {};
              obj = ele.Doctor;
              obj.Specilties = model.filter(
                (b) =>
                  b.Doctor.ID == ele.Doctor.ID &&
                  !b.HospitalSpecialty.Specialty.ParentSpecialtyID
              );
              obj.SubSpecilties = model.filter(
                (b) =>
                  b.Doctor.ID == ele.Doctor.ID &&
                  b.HospitalSpecialty.Specialty.ParentSpecialtyID
              );
              doctorLists.push(obj);
            }
          });
          doctorLists.forEach((d) => {
            d.SubSpecilties.forEach((s) => (s.Doctor = {}));
            d.Specilties.forEach((s) => (s.Doctor = {}));
          });
          this.doctorList = doctorLists;

          this.setSelectedDoctor();
        });
      debugger;
    },
    GetHospitalSurgery() {
      debugger;
      const ID = this.$route.params.HospitalSurgeryID;
      if (ID) {
        this.$vs.loading();
        debugger;
        this.$store
          .dispatch("patientList/GetHospitalSurgery", ID)
          .then((res) => {
            debugger;
            if (res.status == 200) {
              this.treatment = res.data.Data;
    // this.$store.dispatch("SurgeryItemList/GetAllSurgeryItems",this.treatment.Surgery.ID);

              debugger;
            if(this.treatment.HospitalSurgeryDoctors==null||this.treatment.HospitalSurgeryDoctors==undefined){
              this.treatment.HospitalSurgeryDoctors =[];
            }
            if(this.treatment.HospitalSurgeryRequiredDocuments==null||this.treatment.HospitalSurgeryRequiredDocuments==undefined){
              this.treatment.HospitalSurgeryRequiredDocuments =[];
            }
            if(this.treatment.HospitalSurgeryExcludedItems==null||this.treatment.HospitalSurgeryExcludedItems==undefined){
              this.treatment.HospitalSurgeryExcludedItems =[];
            }
            if(this.treatment.HospitalSurgeryIncludedItems==null||this.treatment.HospitalSurgeryIncludedItems==undefined){
              this.treatment.HospitalSurgeryIncludedItems =[];
            }
              if (this.treatment.PercentageDiscount != null) {
                this.$set(this.treatment, "IsPercentage", true);
                debugger;
              } else {
                debugger;
               this.$set(this.treatment, "IsPercentage", false);
              }
              // this.$set(this.treatment, "IsPercentage", true);

              this.getHospitalDoctor();
              if (
                this.Model.Hospital.Rate == null ||
                this.Model.Hospital.Rate == undefined ||
                this.Model.Hospital.Rate == "NaN"
              ) {
                this.Model.Hospital.Rate = 0;
              }
              this.setSelectedDoctor();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
  computed: {
    SurgeryItems()
    {
       return this.$store.state.SurgeryItemList.surgeryItems;
    },
    isFormValid()
    {
       return this.treatment.Surgery.NameEN 
       && this.treatment.Surgery.SurgeryCategoryID
       && this.treatment.Surgery.SpecialtyID
       && this.treatment.DefaultHospitalPrice
       && this.treatment.CurrencyID
    },
    checkPercentage() {
      debugger;
      if (this.treatment.IsPercentage == true) {
        debugger;
        return true;
      } else {
        debugger;
        return false;
      }
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
    filterDoctor() {
      if (this.search.DoctorName) {
        debugger;
        var vm = this;
        return this.doctorList.filter(function (obj) {
          return obj.Name.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) > -1;
        });
      } else return this.doctorList;
    },
    hospitalDoctors() {
      return this.$store.state.HospitalList.hospitalDoctors;
    },
    surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
    specialities() {
      return this.$store.state.HospitalList.hospitalSpecialties;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;


 if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }

    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    
    if(!this.currencies || this.currencies.length==0)
      this.$store.dispatch("CurrencyList/GetAllCurrencies");

    this.$store.dispatch("SurgeryItemList/GetAllSurgeryItemsLookup");

    this.GetHospitalSurgery();
    this.searchHospitalSpecialty();
    
    if(!this.surgeryCategories || this.surgeryCategories.length==0)
      this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");
  },
};
</script>
<style>
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}

#addsurgery .vx-row {
  margin: unset;
}
</style>
